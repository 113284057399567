export const C0 = 16.35;
export const Cd0 = 17.32;
export const D0 = 18.35;
export const Eb0 = 19.45;
export const E0 = 20.6;
export const F0 = 21.83;
export const Fd0 = 23.12;
export const G0 = 24.5;
export const Gd0 = 25.96;
export const A0 = 27.5;
export const Bb0 = 29.14;
export const B0 = 30.87;

export const C1 = 32.7;
export const Cd1 = 34.65;
export const D1 = 36.71;
export const Eb1 = 38.89;
export const E1 = 41.2;
export const F1 = 43.65;
export const Fd1 = 46.25;
export const G1 = 49.0;
export const Gd1 = 51.91;
export const A1 = 55.0;
export const Bb1 = 58.27;
export const B1 = 61.74;

export const C2 = 65.41;
export const Cd2 = 69.3;
export const D2 = 73.42;
export const Eb2 = 77.78;
export const E2 = 82.41;
export const F2 = 87.31;
export const Fd2 = 92.5;
export const G2 = 98.0;
export const Gd2 = 103.83;
export const A2 = 110.0;
export const Bb2 = 116.54;
export const B2 = 123.5;

export const C3 = 130.8;
export const Cd3 = 138.6;
export const D3 = 146.8;
export const Eb3 = 155.6;
export const E3 = 164.8;
export const F3 = 174.6;
export const Fd3 = 185.0;
export const G3 = 196.0;
export const Gd3 = 207.7;
export const A3 = 220.0;
export const Bb3 = 233.1;
export const B3 = 246.9;

export const C4 = 261.6;
export const Cd4 = 277.2;
export const D4 = 293.7;
export const Eb4 = 311.1;
export const E4 = 329.6;
export const F4 = 349.2;
export const Fd4 = 370.0;
export const G4 = 392.0;
export const Gd4 = 415.3;
export const A4 = 440.0;
export const Bb4 = 466.2;
export const B4 = 493.9;

export const C5 = 523.3;
export const Cd5 = 554.4;
export const D5 = 587.3;
export const Eb5 = 622.3;
export const E5 = 659.3;
export const F5 = 698.5;
export const Fd5 = 740.0;
export const G5 = 784.0;
export const Gd5 = 830.6;
export const A5 = 880.0;
export const Bb5 = 932.3;
export const B5 = 987.8;
